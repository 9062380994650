import React from 'react';
import { clsx } from 'clsx';
import styles from '../index.module.scss';
import { MenuItemProps } from './types';

export function MenuItemLabel({
  onMouseEnter,
  onMouseLeave,
  onClick,
  classes,
  title,
  expanded,
}: Pick<
  React.HTMLProps<HTMLDivElement>,
  'onMouseLeave' | 'onMouseEnter' | 'children' | 'onClick'
> &
  Pick<MenuItemProps, 'classes' | 'title'> & { expanded?: boolean }) {
  return (
    <div
      className={clsx(styles.menuItem, !!expanded && styles.expanded)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      <div className={clsx(styles.menuItemTitle, classes?.menuItem)}>
        {title}
      </div>
    </div>
  );
}
