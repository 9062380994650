import Link from 'next/link';
import React, { useState } from 'react';
import styles from './MenuItemDesktop.module.scss';
import { MenuData } from '../types';
import Image from '@/src/shared/components/Image';
import { clsx } from 'clsx';
import { useIsMobile } from '@/src/shared/hooks/utils/useIsMobile';
import { Typography } from '@/src/shared/components/Typography';

interface IProps {
  menuData: MenuData;
}

export function MenuItemDesktop({ menuData }: IProps) {
  const [hovered, setHovered] = useState(0);
  const isWideScreen = useIsMobile('(min-width:1200px)');

  return (
    <div className={styles.container}>
      <div
        className={clsx(
          styles.parentMenu,
          menuData.length > 3 && styles.flexWrap
        )}
      >
        {menuData.map((item, index) => {
          return (
            <div
              onMouseOver={() => setHovered(index)}
              className={styles.parentMenuItem}
              key={index}
            >
              <Link
                href={item?.link}
                rel="nofollow"
                passHref={item.externalLink}
                target={item.externalLink ? '_blank' : undefined}
              >
                <div className={styles.boxMenuItem}>
                  {item.icon && (
                    <div className={styles.svgIcon}>{item.icon}</div>
                  )}
                  <div className={styles.parentTitleMenu}>
                    <div className={styles.boxMenuTitle}>
                      {!item.icon && item.iconSm && (
                        <div className={styles.iconSm}>{item.iconSm}</div>
                      )}
                      <p className={styles.titleMenu}>{item?.title}</p>
                      {item.isNew && (
                        <div className={styles.new}>
                          <Typography
                            variant="text"
                            size="xs"
                            fontWeight="medium"
                          >
                            New
                          </Typography>
                        </div>
                      )}
                    </div>
                    <p className={styles.descriptionMenu}>
                      {item?.description}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
      {isWideScreen && menuData[hovered].image && (
        <Image
          src={menuData[hovered].image}
          alt={menuData[hovered].title}
          width={450}
          height={290}
          priority
        />
      )}
    </div>
  );
}
