import React, { useState } from 'react';
import { MenuItemLabel } from './MenuItemLabel';
import { MenuItemProps } from './types';
import dynamic from 'next/dynamic';
import { useHeader } from '../HeaderProvider';

const MenuItemContent = dynamic(() => import('./MenuItemContent'), {
  ssr: false,
});

export default function MenuItem({ classes, title, children }: MenuItemProps) {
  const { setMenuExpanded } = useHeader();
  const [expanded, setExpanded] = useState(false);

  const handleSetExpanded = (value: typeof expanded) => {
    setExpanded(value);
    setMenuExpanded(value);
  };

  if (title && children == null) {
    return <MenuItemLabel classes={classes} title={title} />;
  }
  return (
    <div>
      <MenuItemLabel
        expanded={expanded}
        onMouseEnter={() => handleSetExpanded(children && true)}
        onMouseLeave={() => handleSetExpanded(false)}
        onClick={() => handleSetExpanded(!expanded)}
        classes={classes}
        title={title}
      >
        {children}
      </MenuItemLabel>
      {expanded && (
        <MenuItemContent
          onMouseEnter={() => handleSetExpanded(true)}
          onMouseLeave={() => handleSetExpanded(false)}
        >
          {children}
        </MenuItemContent>
      )}
    </div>
  );
}
