import React from 'react';
import MenuItem from '@/src/shared/components/HomeHeader/MenuItem/MenuItem';
import MenuDesktop from '@/src/shared/components/HomeHeader/Navigation/MenuItemDesktop';
import { menuData } from './menuData';
import Link from '@/src/shared/components/Link';
import { Routes } from '@/core/routes';
import { clsx } from 'clsx';
import styles from '@/src/shared/components/HomeHeader/index.module.scss';

type Props = {
  menuClasses?: { menuItem?: string };
};

export default function DesktopNavigation({ menuClasses }: Props) {
  return (
    <>
      {menuData.map((menuItem) => {
        return (
          <MenuItem
            key={menuItem.key}
            title={menuItem.title}
            classes={menuClasses}
          >
            <MenuDesktop menuData={menuItem.data} />
          </MenuItem>
        );
      })}
      <MenuItem
        title={
          <Link href={Routes.pricing()}>
            <a className={clsx(styles.link, menuClasses?.menuItem)}>Pricing</a>
          </Link>
        }
      />
    </>
  );
}
